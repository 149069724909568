body {
	height: 100vh;
	margin: 0;
	background-color: #f7f8fb;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rc-pagination-item-active a {
	color: #fff !important;
	background: mediumpurple;
	border: 1px solid mediumpurple;
	border-radius: 15px;
}
.rc-pagination-item {
	border-radius: 15px !important;
}

.rc-pagination-item-active {
	font-weight: 500;
	background: #fff;
	/* border-color: #1890ff; */
	border: none !important;
}

.rc-pagination-prev button {
	content: 'Prev' !important;
	display: block;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
	display: inline-block;
	color: rgba(0, 0, 0, 0.85);
	font-family: Arial;
	line-height: 28px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
	height: 34px !important;
}

.rc-pagination-next button {
	display: block;
	width: 100%;
	height: auto !important;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	transition: all 0.3s;
	font-size: 25px !important;
	border: none !important;
}

.rc-pagination-prev button {
	display: block;
	width: 100%;
	height: auto !important;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	transition: all 0.3s;
	font-size: 25px !important;
	border: none !important;
}

/* .table-responsive {
    overflow-x: scroll;
    display: block;
} */

.table td,
.table th {
	padding: 0.75rem;
	vertical-align: middle !important;
	border-top: 1px solid #dee2e6;
}

.side-layout {
	background: #ffd855 !important;
	border: 1px solid #ffd855;
	border-radius: 12px;
}
.text-user {
	color: #989aa1;
	font-size: 14px;
	font-weight: 500;
	margin-left: 20px;
}
.text-user-1 {
	color: #989aa1;
	font-size: 14px;
	font-weight: 500;
	margin-left: 20px;
}

.image-user {
	color: #989aa1;
	font-size: 14px;
	font-weight: 500;
	margin-left: 20px;
}
.image-user-1 {
	color: #989aa1;
	font-size: 14px;
	font-weight: 500;
	margin-left: 20px;
}

:root {
	--amplify-primary-color: #ffd855;
	--amplify-primary-tint: #ffd855;
	--amplify-primary-shade: #ffd855;
}
::placeholder {
	color: #989aa1;
	font-size: 14px;
}
.styles-module_wrapper__1I_qj {
	width: 80% !important;
	z-index: 1;
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	/* width: 100%; */
	/* height: 100%; */
	background-color: black;
	box-sizing: border-box;
	position: fixed !important;
	left: 19% !important;
	height: 100% !important;
}
.react-datepicker-wrapper {
	padding: 4px !important;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
	display: block;
	width: 100%;
	background: #f7f8fb !important;
	border: none !important;
}
